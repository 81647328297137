<template>
  <transition name="fade" mode="out-in">
    <router-view></router-view>
  </transition>
</template>

<!-- <style lang="scss">
@import './assets/scss/main.scss';
</style> -->
<script>
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/custom.css';
import './css/responsive.css';
import 'jquery/dist/jquery.min.js'; 
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
export default {
  name: 'App',

  created() {
    this.init();
  },

  mounted() {
    //this.initListeners()
    this.resize()
  },

  methods: {
    async init() {
      await this.$store.dispatch('inhaler/fetchAudioStream')
    },

    initListeners() {
      window.addEventListener('resize', () => { this.resize() })
    },

    resize() {
      let vh = window.innerHeight
      let elContainer = document.getElementsByClassName("mobileView");
      setTimeout(()=>{
        elContainer[0].style.height = vh + 'px';
      },100);
    },
  },
}
</script>