// Main layouts
import LayoutSimple from '@/layouts/variations/Simple.vue'

const Landing = () => import("@/views/Landing.vue")
const Start = () => import("@/views/Start.vue")
const Main = () => import("@/views/Main.vue")
const MainCountdown = () => import("@/views/main/Countdown.vue")
const MainInhaler = () => import("@/views/main/Inhaler.vue")
const MainResult = () => import("@/views/main/Result.vue")
const Share = () => import("@/views/Share.vue")
const ShareConfirmation = () => import("@/views/ShareConfirmation.vue")
const About = () => import("@/views/About.vue")
const Terms = () => import("@/views/Terms.vue")
const Privacy = () => import("@/views/Privacy.vue")
const Prompt = () => import("@/views/Prompt.vue")
const Instructions = () => import("@/views/Instructions.vue")
const Video = () => import("@/views/instructions/Video.vue")
const Step1 = () => import("@/views/instructions/Step-1.vue")
const Step2 = () => import("@/views/instructions/Step-2.vue")
const Step3 = () => import("@/views/instructions/Step-3.vue")
const MainSelectInhaler = () => import("@/views/main/SelectInhaler.vue")

export default [
  {
    path: '/',
    redirect: '/landing',
    component: LayoutSimple,
    children: [
      {
        path: '/landing',
        name: 'Landing',
        component: Landing,
        meta: { backgroundImage: 'url("images/bg.jpg")', bodyClass: 'common-dark-bg', hideHeader: true },
      },
      {
        path: '/instructions',
        name: 'instructions',
        redirect: '/instructions/step-1',
        component: Instructions,
        children: [
          {
            path: 'video',
            name: 'Video',
            component: Video,
            meta: { backgroundImage: 'url("images/bg2.png")', bodyClass : 'common-light-bg'}
          },
          {
            path: 'step-1',
            name: 'Step 1',
            component: Step1,
            meta: { backgroundImage: 'url("images/bg.jpg")', bodyClass: 'common-dark-bg'}
          },
          {
            path: 'step-2',
            name: 'Step 2',
            component: Step2,
            meta: { backgroundImage: 'url("images/bg2.png")', bodyClass: 'common-light-bg'}
          },
          {
            path: 'step-3',
            name: 'Step 3',
            component: Step3,
            meta: { backgroundImage: 'url("images/bg2.png")', bodyClass : 'common-light-bg'}
          }
        ]
      },
      {
        path: '/prompt',
        name: 'Prompt',
        component: Prompt,
        meta: { backgroundImage: 'url("images/bg2.png")', bodyClass : 'common-light-bg'}
      },
      {
        path: '/start',
        name: 'Start',
        component: Start,
        meta: { backgroundImage: 'url("images/bg.jpg")'}
      },
      {
        path: '/main',
        name: 'main',
        redirect: '/main/countdown',
        component: Main,
        children: [
          {
            path: 'countdown',
            name: 'Countdown',
            component: MainCountdown,
            meta: { backgroundImage: 'url("images/bg.jpg")'}
          },
          {
            path: 'inhaler',
            name: 'Inhaler',
            component: MainInhaler,
            meta: { backgroundImage: 'url("images/bg2.png")', bodyClass : 'common-light-bg'}
          },
          {
            path: 'select-inhaler',
            name: 'Select Inhaler',
            component: MainSelectInhaler,
            meta: { backgroundImage: 'url("images/bg2.png")', bodyClass : 'common-light-bg'}
          },
          {
            path: 'result',
            name: 'Result',
            component: MainResult,
            meta: { backgroundImage: 'url("images/bg2.png")', bodyClass : 'common-light-bg'}
          },
        ]
      },
      {
        path: '/share',
        name: 'Share',
        component: Share
      },
      {
        path: '/shareConfirmation',
        name: 'ShareConfirmation',
        component: ShareConfirmation
      },
      {
        path: '/about',
        name: 'About',
        component: About,
        meta: { backgroundImage: 'url("images/bg2.png")'}
      },
      {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        meta: { backgroundImage: 'url("images/bg2.png")'}
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy,
        meta: { backgroundImage: 'url("images/bg2.png")'}
      },
    ]
  },
]
