export const deviceList = [
    {
        name: 'pMDI',
        description: 'Pressurized Metered Dose Inhaler',
        image: 'pMDI-inhaler',
        optimalMin: 28,
        optimalMax: 500,
        // resistanceFactor: 1,
        resistanceFactor: 0.70
    },
    {
        name: 'SMI',
        description: 'Soft Mist Inhaler',
        image: 'smi-inhaler',
        optimalMin: 28,
        optimalMax: 500,
        // resistanceFactor: 1,
        resistanceFactor: 0.70
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'dpi-inhaler-1',
        optimalMin: 50,
        optimalMax: 500,
        // resistanceFactor: 0.83,
        resistanceFactor: 0.60
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'dpi-inhaler-2',
        optimalMin: 63,
        optimalMax: 500,
        // resistanceFactor: 0.664,
        resistanceFactor: 0.48
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'dpi-inhaler-3',
        optimalMin: 63,
        optimalMax: 500,
        // resistanceFactor: 0.664,
        resistanceFactor: 0.48
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'dpi-inhaler-4',
        optimalMin: 79,
        optimalMax: 500,
        // resistanceFactor: 0.498,
        resistanceFactor: 0.38
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'dpi-inhaler-5',
        optimalMin: 107,
        optimalMax: 500,
        // resistanceFactor: 0.332,
        resistanceFactor: 0.28
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'dpi-inhaler-6',
        optimalMin: 120,
        optimalMax: 500,
        // resistanceFactor: 0.166,
        resistanceFactor: 0.25
    },
]