/*
* @Author: babysoft
* @Date:   2023-09-04 12:20:42
* @Last Modified by:   babysoft
* @Last Modified time: 2023-09-04 12:20:52
*/
export default {
  created() {
    const routeMeta = this.$route.meta;
    if (routeMeta && routeMeta.backgroundImage) {
      document.body.style.backgroundImage = routeMeta.backgroundImage;
    } else {
      document.body.style.backgroundImage = ''; // Clear background image if not set
    }
  },
};