/*
* @Author: abhi
* @Date:   2023-09-05 12:01:47
* @Last Modified by:   abhi
* @Last Modified time: 2023-09-05 13:03:44
*/
export default async (to, from, next) => {
	if (to.path === '/instructions/step-1') {
		next({ path: '/instructions/step-1' })
	} else {
		next()
	}
}