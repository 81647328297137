import Vue from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
import BootstrapVue from 'bootstrap-vue';
// Import translation plugin and translation files
import VueI18n from 'vue-i18n';
import enLocale from './locales/en.json';
import frLocale from './locales/fr.json';
// Import the global mixin
import backgroundImageMixin from './components/extra/backgroundImageMixin';
import bodyClassMixin from './components/extra/bodyClassMixin';
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(VueI18n);



Vue.config.productionTip = false

// Apply the global mixin
Vue.mixin(backgroundImageMixin);
Vue.mixin(bodyClassMixin);

const i18n = new VueI18n({
  locale: 'en', // Set the default locale
  messages: {
    en: enLocale,
    fr: frLocale,
  },
});

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

// set initial view (necessary then using 'abstract' mode)
router.replace('/')