<template>
  <header class="headerSection trs">
    <nav class="navbar d-flex justify-content-between">
      <button class="navbar-toggler" type="button" @click="redirectBack()">
        <span class="navbar-toggler-icon"><i class="fa-solid fa-arrow-left"></i></span>
      </button>
      <button class="navbar-toggler ms-auto" type="button" @click="toggleNavbar">
        <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
      </button>
      <div class="collapse navbar-collapse justify-content-center" id="navBar" :class="{ show: isNavbarOpen }">
        <ul class="navbar-nav">
          <li class="nav-item"> <a href="#" class="nav-link" @click="closeNavbar('about')">About this App</a></li>
          <li class="nav-item"> <a href="#" class="nav-link" @click="closeNavbar('terms')">Terms of Use</a></li>
          <li class="nav-item"> <a href="#" class="nav-link" @click="closeNavbar('privacy')">Privacy Policy</a></li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isNavbarOpen: false
    };
  },
  methods: {
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    closeNavbar(redirectPage) {
      if (redirectPage) {
        this.$router.push('/' + redirectPage);
      }
      this.isNavbarOpen = false;
    },
    redirectBack() {
      this.$router.push('/');
    }
  }
}
</script>