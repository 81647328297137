<template>
  <!-- Desktop View Start -->
  <div id="page-container">
    <div class="container" v-if="!mobileCheck">
      <div class="row">
        <div class="col-12 d-none d-md-block">
          <div class="desktop-view text-center d-flex align-items-center justify-content-center vh-100">
            <h1>Oups</h1>
            <p>
              Please use your smartphone for an<br>enhanced inhaling experience.
            </p>
          </div>
        </div>
      </div>  
    </div>
    <!-- ./ End Desktop View -->
    <!-- Mobile View Start -->
    <div class="mobileView d-md-none d-block">
      <app-header v-if="!$route.meta.hideHeader"></app-header>
      <main role="main" class="main-section-area">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </main>
    </div>
    <!-- ./ End Desktop View -->
  </div>
</template>

<script>
import AppHeader from '~/layouts/partials/AppHeader.vue'

export default {
  name: 'BaseLayout',
  components: {
    AppHeader,
  },

  data () {
    return {
      ua: null,
    }
  },

  computed: {
    mobileCheck() {
      return this.deviceType == "mobile"
    },
    deviceType() {
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(this.ua)) {
        return "tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          this.ua
        )
      ) {
        return "mobile";
      }
      return "desktop";
    }
  },

  mounted() {
    this.ua = navigator.userAgent
  },

}
</script>
